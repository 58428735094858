.main {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  padding: 0 8px;
}
.slot {
  width: 100%;
  height: 100%;
}
.card {
  width: 100%;
  height: 100%;
}

.closeWrap {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  img {
    width: 100%;
  }
}