.main {
    width: 100%;
    position: relative;
    * {
        box-sizing: border-box;
    }
}

.sliderInput {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-appearance: none;
    appearance: none;
    width: 100%; 
    height: 6px; 
    outline: none; 
    cursor: pointer; 
    margin-top: 10px;
    background: transparent;
    z-index: 2;
}

.sliderWrap {
    width: 100%; 
    height: 6px; 
}

.sliderBg {
    position: absolute;
    top: 10px;
    left: 15px;
    width: calc(100% - 24px); 
    height: 4px; 
    border-radius: 5px;
    background: rgba($color: #072b24, $alpha: 1);
    z-index: 1;
}

.shadowSlider {
    position: absolute;
    height: 4px;
    top: 10px;
    left: 15px;
    background: #1df4a3;
    border-radius: 5px;
    z-index: 1;
}

.sliderInput::-webkit-slider-thumb {
    -webkit-appearance: none; 
    appearance: none;
    width: 20px; 
    height: 30px; 
    background: url("../../images/slider.svg") no-repeat;
    background-position: center;
}

.inputValue {
    margin-left: 30px;
    margin-top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 700;
    font-size: 22px;
    color: #ffffff;
    display: flex;
    align-items: center;
}
.solanaLogo {
  height: 20px;
  margin-right: 8px;
}
@media screen and (max-width: 1280px) {
    .sliderInput {
        height: 4px; 
        margin-top: 7px;
    }
    
    .sliderWrap {
        height: 4px; 
    }
    
    .sliderBg {
        height: 3px; 
        top: 7px;
        left: 10px;
        border-radius: 3px;
    }
    
    .shadowSlider {
        height: 3px;
        top: 7px;
        left: 10px;
        border-radius: 4px;
    }
    
    .sliderInput::-webkit-slider-thumb {
        width: 20px; 
        height: 20px; 
    }
    
    .inputValue {
        width: 33px;
        margin-left: 20px;
        font-size: 14px;
    }
}