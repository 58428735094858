.main {
  padding: 4px;
  width: 50%;
  position: relative;
  box-sizing: border-box;
}
.nftImgContainer{
  width: 100%;
}
.popup {
  padding: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 20px;
  box-sizing: border-box;
  >div {
    height:40px;
    width: 100%;
    background: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #248dab;
    cursor: pointer;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 992px) {
  .main {
    width: 220px;
  }
  .nftImgContainer {
    img {
      width: 220px;
    }
  }
}