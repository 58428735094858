.main {
  display: flex;
  position: relative;
  height: 100%;
  color: #ffffff;
  h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 45px;
    text-align: center;
    margin: 18px 0 0 0;
    font-weight: 600
  }
}
.blurWrap {
  filter: blur(3px);
}
.wrap {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}
.content {
  display: flex;
  height: calc(100% - 99px);
}
.underline {
  margin: auto;
  width: 280px;
  height: 10px;
  margin: 8px auto;
  background-image: url("../../images/Images/line_titles.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.leftSideWrap{
  display: block;
  max-width: 25%;
  width: 100%;
  height: 100%;
  max-height: 800px;
}
.rightSideWrap {
  max-width: 75%;
  width: 100%;
}
.nftHeader {
  background: url("../../images/Furnace/frameYourNFTs.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  img {
    position: relative;
    top: 3  px;
    height: 25px;
  }
}
.divisor {
  border-top: 1px solid #00a2ca;   
  margin: 8px 0 8px 0;
  height: 10px;
  background: radial-gradient(at 50% 0, #00A2CA, transparent 80%);
}

.cardList {
  width: 100%;
  height: calc(100% - 100px);
  overflow: auto;
  flex-wrap: wrap;
  display: flex;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.8);
  }
  &::-webkit-scrollbar-thumb {
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #d3d3d3;
  }
  img {
    width: 100%;
  }
}

.loaderMain {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}
.loaderWrap {
  position: relative;
  .loaderBg {
    width: 100%;
  }
  .oprorBW {
    position: absolute;
    top: -82px;
    right: -65px;
    width: 130px;
  }
  .oprorBWActive {
    position: absolute;
    top: -106px;
    right: -87px;
    z-index: 5;
    width: 175px;
  }
}
.gaugeWrap {
  display: flex;
  position: relative;
}
.gauge {
  width: 20%;
  position: relative;
  padding-left: 8px;
  padding-top: 4px;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 600;
  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.gaugeLegendary  {
  position: absolute;
  right: -30px;
  top: 19px;
  z-index: 10;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 600;
}
.slotWrap {
  display: flex;
  width: 100%;
}

.successWrap {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-around;
  position: relative;
  margin-top: 20px;
  .successWrapBg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url("../../images/Furnace/lineBack.svg");
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }
  img {
    width: 120px;
  }
  .barRate:nth-child(even) {
    margin-top: 20px;
    background-position: 50% 72%;
  }
  .barRate {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url("../../images/Furnace/frameRate2.svg");
    background-repeat: no-repeat;
    background-position: 50% 135%;
    background-size: 160%;
    z-index: 5;
    margin-top: 5px;
    p {
      margin: 0 0 0 0;
      font-size: 20px;
      font-family: 'Russo one', sans-serif;
    }
    span {
      position: absolute;
      top: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      font-family: 'Montserrat', sans-serif;
      color: #ffffff;
      text-shadow: 1px 1px 5px #FF4E00
    }
    .barRateBg {
      opacity: 0.5;
      
    }
    .barRateFill {
      overflow: hidden;
      transition: height 0.5s;
    }
    .barRateFillWrap {
      height: 130px;
      position: absolute;
      top: 0;
      left: 2px;
    }
  }

.barRateNumber:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 25px solid #000000;
  border-left: 44px solid transparent;
  border-right: 44px solid transparent;
  position: absolute;
  top: -25px;
}

.barRateNumber {
  margin-top: 30px;
  width: 88px;
  height: 50px;
  background-color: #000000;
  position: relative;
}

.barRateNumber:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -25px;
  left: 0px;
  border-top: 25px solid #000000;
  border-left: 44px solid transparent;
  border-right: 44px solid transparent;
}
 
}
.bottom {
  display: flex;
  margin-top: 100px;
  justify-content: space-between;
}
.loadingBar {
  position: absolute;
  left: 0;
  top: 6px;
  height: 10px;
  transition: background-color 1s, width 1s, box-shadow 1s;
}
.gasWrap {
  background: rgba(0,0,0, 0.5);
  padding: 20px 20px;
  font-size: 26px;
  font-family: 'Russo one', sans-serif;
  width: 60%;
  margin-left: 8px;
  h4 {
    margin: 8px 0
  }
}
.burnWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.footerButtonWrap {
  filter: drop-shadow(-4px 4px 0 #000000);
  &:active {
    filter: drop-shadow(-2px 2px 0 #000000);
    transform: translate(-2px, 2px);
  }
  .footerButton {
    width: 240px;
    height: 40px;
    font-size: 24px;
    color: #ffffff;
    background: linear-gradient(rgba(250, 204, 34, 0.9), rgba(255, 153, 0, 0.9));
    clip-path: polygon(5% 100%, 0 0, 0 0, 100% 0, 95% 100%, 0% 100%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.popupWrap {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  img {
    height: 80%;
    max-height: 700px;
  }
}
.loading {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 20px;
  >span {
    margin-top: -30px;
  }
  >div {
    height: 300px;
    width: 300px;
  }
}
.mobile {
  display: block;
}
.bottomNft {
  display: none;
}
.tooltipMain {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tooltip {
  z-index: 15;
  cursor: pointer;
  max-width: 450px;
  padding: 12px 32px;
  border: 1px solid #00a2ca;
  background: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  h4 {
    font-family: 'Russo one', sans-serif;
    color: #fc953a;
    font-size: 22px;
    margin: 0 0 8px 0;
  }
}
@media screen and (max-width: 992px) {
  .main {
    h3 {
      font-size: 30px;
      margin: 4px 0 0 0;
    }
  }
  .underline {
    margin: 4px auto;
  }
  .leftSideWrap {
    display: none;
  }
  .rightSideWrap {
    max-width: 100%;
  }
  .successWrap {
    img {
      width: 100px;
    }
  }
  .barRateFillWrap {
    left: 0px !important;
  }
  .barRate {
    p {
      font-size: 16px !important;
    }
  }
  .barRateNumber {
    font-size: 20px !important;
  }

  .barRateNumber:before {
    content: " ";
    width: 0;
    height: 0;
    border-bottom: 22px solid #000000 !important;
    border-left: 38px solid transparent !important;
    border-right: 38px solid transparent !important;
    position: absolute;
    top: -21px !important;
  }
  
  .barRateNumber {
    margin-top: 22px !important;
    width: 74px !important;
    height: 42px !important;
    background-color: #000000 !important;
    position: relative;
  }
  
  .barRateNumber:after {
    content: "";
    width: 0;
    position: absolute;
    bottom: -22px !important;
    left: 0px !important;
    border-top: 22px solid #000000 !important;
    border-left: 38px solid transparent !important;
    border-right: 38px solid transparent !important;
  }

  .mobileHide {
    display: none;
  }
  .bottom {
    flex-direction: column;
    margin-top: 30px;
    .gasWrap {
      width: calc(100% - 10px);
      padding: 4px;
      font-size: 18px;
      margin-left: 0;
      h4 {
        margin: 2px 0
      }
    }
  }
  .burnWrap {
    margin-top: 8px;
  }
  .loaderMain {
    padding: 4px;
    width: calc(100% - 35px);
  }

  .oprorBW {
    position: absolute;
    top: -45px !important;
    right: -33px !important;
    width: 80px !important;
  }
  .oprorBWActive {
    position: absolute;
    top: -41px !important;
    right: -33px !important;
    z-index: 5;
    width: 80px !important;
  }
  .bottomNft {
    display: flex;
    flex-direction: column;
    margin: 16px 0 0 0;
  }
  .cardList {
    display: flex;
    width: 100%;
    overflow: auto;
    height: auto;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0,0,0,0.8);
    }
    &::-webkit-scrollbar-thumb {
      background: #ffffff;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #d3d3d3;
    }
  }
  .footerButtonWrap {
    margin-top: 12px;
  }
  .gauge { 
    font-size: 12px;
  }
  .gaugeLegendary {
    font-size: 12px;
  }
}
