.main {
  background: repeating-linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.05) 1px,
    rgba(0, 0, 0, 0.05) 1px,
    rgba(0, 0, 0, 0.05) 5px),
    linear-gradient(to bottom, rgba(0,0,0,0.8) 40%, rgba(49, 176, 222, 0.8) 220%);
    height: 100vh;
    overflow: auto;
}